import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import ReactDOM from "react-dom"
import AnchorLink from "react-anchor-link-smooth-scroll"

import { Container } from "../global"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      scribeImage: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "scribe" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      workImage: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "work" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allMarkdownRemark(sort: { fields: [frontmatter___order], order: ASC }) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              anchor
              id
              title
              date(formatString: "DD MMMM, YYYY")
              order
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `)

  // const handleSubmit = (event) => {
  //   event.preventDefault()
  // }

  return (
    <HeaderWrapper id="top">
      <Container>
        <IntroGrid>
          <IntroItem>Dr. Fred Pinnegar, PhD</IntroItem>
          <IntroItem>Professional Writing Services</IntroItem>
          <ContactLine>Phone: 801-368-6005</ContactLine>
          <ContactLine>
            Email: <a href="mailto:pinnegarf@gmail.com">pinnegarf@gmail.com</a>
          </ContactLine>
        </IntroGrid>
        <Quote>
          I enable you to effectively say what you think and feel—That’s what
          rhetoricians do.
        </Quote>

        <IntroGrid>
          <GridItem>
            <strong>What is a “Rhetorician?”</strong> Pronounced:
            rhet·​o·​ri·​cian | \ re-tə-ˈri-shən\ (Merriam Webster){" "}
            <Definition>
              <Subtitle>An expert in the effective use of language</Subtitle>{" "}
              <Subtitle>
                One who knows the ancient art or science of all specialized uses
                of language in prose or verse
              </Subtitle>
              <Subtitle>
                One who practices in speech or writing the art of influencing
                the thought and conduct of others
              </Subtitle>{" "}
              <Subtitle>A person who teaches rhetoric</Subtitle>
            </Definition>
            <HeaderTextGroupBox>
              <BoxHeader>Menu of Professional Writing Services</BoxHeader>
              <ul>
                {data.allMarkdownRemark.edges.map(({ node }) => (
                  <div key={node.id}>
                    {/* <Link to={node.fields.slug}> */}
                    <AnchorLink href={
                      node.frontmatter.anchor
                    }>
                      <Subtitle>{node.frontmatter.title}</Subtitle>
                    </AnchorLink>
                  </div>
                ))}
              </ul>
            </HeaderTextGroupBox>
          </GridItem>
          <GridItem>
            <StyledImage fluid={data.scribeImage.childImageSharp.fluid} />
            <br />
            <div style={{ border: "1px solid black", padding: "5px" }}>
              Contact the Rhetoricians by phone or email and tell us about your
              current frustrations with the written word. Fast turnaround and
              reasonable prices.
            </div>
            <StyledImage fluid={data.workImage.childImageSharp.fluid} />
          </GridItem>
        </IntroGrid>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 80px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`

const Intro = styled.h2`
  display: flex;
  justify-content: space-around;
  font-size: 1.75rem;
  padding: 10px 20px;
  font-family: candara;
  color: #ff0066;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    font-size: 1rem;
  }
`

const IntroGrid = styled.div`
  /*max-width: 670px;*/
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 100px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const GridItem = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  ${"" /* color: #ff0066; */}
  font-family: candara;
  ${"" /* font-size: 1.75rem; */}
  ${"" /* font-weight: bold; */}
`

const BoxHeader = styled.span`
  color: #6328f8;
  font-size: 1.25rem;
  font-weight: bold;
  font-family: candara;
  margin: 10px;
  padding: 10px;
`

const IntroItem = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  color: #ff0066;
  font-family: candara;
  font-size: 1.75rem;
  font-weight: bold;
`

const Quote = styled.h2`
  display: flex;
  justify-content: center;
  font-size: 20px;
  padding: 10px 20px;
  font-family: timesNewRoman;
  background-color: #ebecf0;
`

const Subtitle = styled.li`
  font-size: 16px;
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  margin-bottom: 20px;
`

const ContactLine = styled.h5`
  font-size: 16px;
  letter-spacing: 0px;
  margin: 5px;
  text-align: center;
`

const Definition = styled.ol`
  font-size: 16px;
  letter-spacing: 0px;
  margin-left: 10px;
`

const HeaderTextGroupBox = styled.div`
  margin: 0;
  padding: 5px;
  border: 1px solid black;
`

const HeaderTextGroup = styled.div`
  margin: 0;
  padding: 5px;

  > div {
    width: 100%;
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${(props) => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${(props) => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const FormSubtitle = styled.span`
  ${(props) => props.theme.font_size.xxsmall}
`

const FormSubtitleLink = styled(Link)`
  color: ${(props) => props.theme.color.secondary};
  padding-bottom: 1px;
  margin-left: 8px;
  text-decoration: none;
  border-bottom: 1px solid ${(props) => props.theme.color.secondary};
`

const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${(props) => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  justify-self: inline;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 300px;
  display: flex;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 300px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`
