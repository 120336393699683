import React from "react"
import styled from "styled-components"

import { graphql, useStaticQuery, Link } from "gatsby"
import { Section, Container } from "../global"

const Topics = () => {
  const data = useStaticQuery(graphql`
    query {
      scribeImage: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "scribe" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      workImage: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "work" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allMarkdownRemark(sort: { fields: [frontmatter___order], order: ASC }) {
        totalCount
        edges {
          node {
            id
            html
            frontmatter {
              anchor
              id
              title
              date(formatString: "DD MMMM, YYYY")
              order
              photo
              pricing
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `)
  // const handleSubmit = (event) => {
  //   event.preventDefault()
  // }

  return (
    <HeaderWrapper id="top">
      <Container>
      <GridItem>
        <SectionTitle>
          Professional Writing Services
        </SectionTitle>
      </GridItem>
        <IntroGrid>
        </IntroGrid>
        {data.allMarkdownRemark.edges.map(({ node }) => (
                  <Section key={node.id} id={node.frontmatter.id}>
                      <div>
                        <TopicTitle>{node.frontmatter.title}</TopicTitle>
                        <div dangerouslySetInnerHTML={{ __html: node.html }} />
                      </div>
                      <TopicImage
                        src={"/static/" + node.frontmatter.photo}
                        alt={node.frontmatter.alt}
                        style={{ maxWidth: "350px" }}
                      ></TopicImage>
                      <div>{node.frontmatter.pricing}</div>
                  </Section>
                ))}
      </Container>
    </HeaderWrapper>
  )
}

export default Topics

const StyledContainer = styled(Container)``

const HeaderWrapper = styled.header`
  padding: 80px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`

const TopicImage = styled.img`
  max-width: 350px;
`

const TopicTitle = styled.h4`
  display: flex;
  ${'' /* justify-content: center; */}
  margin: 0 auto 40px;
  ${'' /* text-align: center; */}
`

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const TopicsGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 250px;
`

const IntroGrid = styled.div`
  /*max-width: 670px;*/
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 100px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const GridItem = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  ${"" /* color: #ff0066; */}
  font-family: candara;
  ${"" /* font-size: 1.75rem; */}
  ${"" /* font-weight: bold; */}
`
